!<template>
  <div class="inviteOther">
    <el-dialog :title="title" :visible.sync="dialogVisible" width="600px">
      <div>
        <div v-if="!invitedRecord" class="invitedRecord">
          <el-form :rules="rulesForm" ref="form" :model="form">
            <el-form-item prop="email">
              <div class="clearfix">
                <el-input
                  v-model="email"
                  size="small"
                  :placeholder="$t('enter_email')"
                  class="inputWidth"
                ></el-input>
                <el-button
                  size="small"
                  class="fr inviteBtn mrBtn"
                  @click="toggleRecordC"
                  >{{ $t("inviteList") }}</el-button
                >
                <el-button
                  size="small"
                  type="primary"
                  class="fr mrBtn"
                  @click="inviteChange"
                  >{{ $t("invite") }}</el-button
                >
              </div>
            </el-form-item>
          </el-form>

          <div class="clearfix matters" v-if="!chatInfo.href">
            <div class="fl">{{ $t("notice") }}：</div>
            <div class="fl info">
              <div>{{ $t("oneMeetingCould") }}</div>
              <!-- <div>2.邀请参会人邮箱不支持修改和删除；</div>
              <div>3.已邀请参会人再次邀请，会再次发送邀请邮件</div> -->
            </div>
          </div>
          <div class="meetingList" v-else>
            <div class="linkItem">
              <div>{{ $t("meetingRoomLink") }}：</div>
              <div class="clearfix hrefDiv">
                <div class="fl textOverflow href">
                  {{ chatInfo.href }}
                </div>
                <el-button
                  class="fr"
                  type="text"
                  :disabled="chatInfo.user_id != this.USER_INFO.id"
                  size="small"
                  @click="coptText(chatInfo.password)"
                  >{{ $t("copyInfo") }}</el-button
                >
              </div>
              <div class="channelId">
                <div>{{ $t("loginPassword") }}：{{ chatInfo.password }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="tableBlock">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="nick_name" :label="$t('designation')">
              <template slot-scope="scope">
                <div>{{ scope.row.nick_name | textFormat }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('email')">
              <template slot-scope="scope">{{
                scope.row.email | textFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="name" :label="$t('inviter')" width="100">
              <template slot-scope="scope">{{
                scope.row.name | textFormat
              }}</template>
            </el-table-column>
            <el-table-column prop="address" :label="$t('handle')" width="100">
              <template slot-scope="scope">
                <template v-if="scope.row.user_id == USER_INFO.id">
                  <el-button
                    type="text"
                    size="small"
                    @click="coptText(scope.row.password, scope.row)"
                    >{{ $t("copyInfo") }}</el-button
                  >
                </template>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" v-if="invitedRecord">
        <el-button @click="invitedRecord = false" size="small">{{
          $t("back")
        }}</el-button>
        <el-button type="primary" size="small" @click="dialogVisible = false">{{
          $t("close")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMultiChatPage } from "~/baseUtils";
import { validateEmail } from "~/baseUtils/validate";
export default {
  data() {
    return {
      dialogVisible: false,
      title: this.$t("SendInvitation"),
      email: "",
      form: {},
      invitedRecord: false,
      chatInfo: {},
      tableData: [],
      copyDisabled: false,
      live_id: "",
      daily_one2one_chat_id: "",
      rulesForm: {
        email: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (!this.email) {
                return callback(new Error(" "));
              } else if (!validateEmail(this.email)) {
                return callback(new Error(this.$t("wrongEmailFormat")));
              } else {
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    inviteIsShow(type, val) {
      this.dialogVisible = type;
      this.chatInfo = val;
      this.live_id = val.live_id;
      this.daily_one2one_chat_id = val.daily_one2one_chat_id;
      this.email = "";
      this.chatInfo.href = "";
      this.invitedRecord = false;
      this.$nextTick((e) => {
        this.$refs.form.clearValidate();
      });
    },
    inviteChange() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let params = {
            user_id: this.USER_INFO.id,
            daily_one2one_chat_id: this.daily_one2one_chat_id,
            live_id: this.live_id,
            email: this.email,
          };
          let result = await this.$store.dispatch(
            "baseConsole/inviteMultChat",
            params
          );
          if (result.success) {
            let path = getMultiChatPage(
              this.live_id,
              this.USER_INFO.id,
              result.invite_id,
            );
            this.email = "";
            this.chatInfo.href = path;
            this.chatInfo = Object.assign(this.chatInfo, result.data);
            this.chatInfo.user_id = this.USER_INFO.id;
          } else {
            if (result.errorcode == 1037) {
              // this.$message.warning(this.$t("repeatTheBooking"));
              // this.getList(params.email);
              this.chatInfo = result.data;
              let path = getMultiChatPage(
                this.live_id,
                this.USER_INFO.id,
                this.chatInfo.invite_id
              );
              this.email = "";
              this.chatInfo.href = path;
            } else if (result.errorcode == 1038) {
              this.$message.warning(this.$t("atMostInvitee4"));
            } else {
              this.email = "";
            }
          }
        }
      });
    },
    async getList() {
      try {
        let params = {
          live_id: this.live_id,
        };

        let result = await this.$store.dispatch(
          "baseConsole/myMultChatRecord",
          params
        );
        if (result.success) {
          this.tableData = result.data;
        } else {
          this.tableData = [];
        }
      } catch (e) {}
    },
    toggleRecordC() {
      this.invitedRecord = true;
      this.getList();
    },
    coptText(password, row) {
      if (!password) return;
      let identify, invite_id;
      if (row) {
        identify = row.identify;
        invite_id = row.invite_id;
      } else {
        identify = this.chatInfo.identify;
        invite_id = this.chatInfo.invite_id;
      }
      let path = getMultiChatPage(this.live_id, identify, invite_id);
      var name = this.$t("dianjidenglu") + " \n";
      var detail = this.$t("loginPassword") + "：" + password + " \n";
      var href = this.$t("loginlink") + "：" + path;
      var content = name.concat(detail, href);
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${content}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
  },
};
</script>

<style lang="less" scoped>
.inputWidth {
  width: 68%;
  margin-right: 16px;
}
.invitedRecord {
  padding-bottom: 30px;
  /deep/.el-button+.el-button{
    margin-left: 0;
  }
}
.matters {
  margin-top: 30px;
  div {
    color: #909399;
  }
  .info {
    max-width: 80%;
    // line-height: 24px;
  }
}
.inviteBtn {
  margin-left: 8px;
}
.mrBtn {
  margin-top: 3px;
}
.meetingList {
  margin-top: 30px;
}
.linkItem {
  width: 100%;
  background: #f2f2f2;
  margin-top: 12px;
  padding: 12px 24px;
  .hrefDiv {
    margin-top: 8px;
    .href {
      width: 88%;
      // border: 1px solid #999999;
      padding: 6px 3px;
    }
  }
  .channelId {
    margin-top: 8px;
    // .fl {
    //   width: 50%;
    //   padding-right: 8px;
    // }
  }
}
</style>